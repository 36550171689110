export const scrollTo = (distance: number = window.innerHeight) => {
  window.scrollTo({
    top: distance,
    behavior: 'smooth'
  });
};

export const setBodyOverflowHidden = (hide: boolean) => {
  const body: HTMLElement | null = document.querySelector('body');
  if (body) {
    if (hide) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
  }
};
