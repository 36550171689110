import { i18n } from '@/assets/translations';

type AppContact = Readonly<{
  address: string;
  email: string;
  bknJewelryEmail: string;
  instagramLink: string;
  linkedinLink: string;
  phone: string;
}>;

export const appContact: AppContact = Object.freeze({
  get address(): string {
    return i18n.global.t('app.location');
  },
  email: import.meta.env.VITE_APP_CONTACT_EMAIL,
  bknJewelryEmail: 'laure.monge@bkn-jewelry.ch',
  instagramLink: 'https://www.instagram.com/broknjewelry/',
  linkedinLink: 'https://www.linkedin.com/company/brokn-jewelry',
  phone: '+41 079 484 37 97'
});
