import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import * as Sentry from '@sentry/vue';

import App from '@/App.vue';
import { router } from '@/router';
import { i18n } from '@/assets/translations';
import { useLocaleStore } from '@/stores';

import '@/assets/styles/scss/index.scss';

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  ignoreErrors: [/.*unauthorized access.*/],
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE),
  tracePropagationTargets: ['localhost', 'recette.brokn.ch', 'brokn.ch'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(i18n);
app.mount('#app');

i18n.global.locale.value = useLocaleStore().value;
