<script setup lang="ts">
import { watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import { useUiStore } from '@/stores';
import { setBodyOverflowHidden } from '@/helpers';

const uiStore = useUiStore();

const route = useRoute();
const withoutLoader = computed(() => route.meta?.withoutLoader ?? false);

watch(
  () => uiStore.navMenuOpen,
  (navMenuOpen) => setBodyOverflowHidden(navMenuOpen)
);

watch(
  () => uiStore.loading,
  (loading) => setBodyOverflowHidden(loading)
);

watch(
  () => withoutLoader.value,
  (withoutLoader) => withoutLoader && (uiStore.loading = false)
);
</script>

<template>
  <Navbar v-if="!$route.meta.hideComponent" />
  <Transition name="fade">
    <Loader v-if="uiStore.loading" />
  </Transition>
  <main class="flex flex-col min-h-screen relative overflow-x-hidden">
    <RouterView />
    <div class="fixed bottom-4 right-4 z-20 md:bottom-6 md:right-6">
      <ContactButton v-if="!$route.meta.hideComponent" />
    </div>
  </main>
  <Footer v-if="!$route.meta.hideComponent" />
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-700;
}

.fade-leave-to {
  @apply opacity-0;
}
</style>
