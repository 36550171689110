<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { useUiStore, type UiState, useLocaleStore } from '@/stores';

const { t, locale: currentLocale, availableLocales } = useI18n();
const uiStore: UiState = useUiStore();
const localeStore = useLocaleStore();

const closeMenu = () => (uiStore.navMenuOpen = false);

const changeLocale = (locale: string) => localeStore.setLocale(locale);
</script>

<template>
  <nav
    class="flex items-center justify-between fixed top-0 z-50 bg-navbar-gradient text-white text-xs h-navbar w-full px-4 md:px-8"
  >
    <div class="flex-center-center gap-4">
      <BurgerMenu />
      <RouterLink
        :to="{ name: 'home' }"
        class="flex-center-center gap-2 text-white"
        :aria-label="$t('navigation.home.title')"
        @click="closeMenu"
      >
        <Icon icon-name="BrandLogo" class="w-6 h-6" />
        <Icon icon-name="BrandTitle" class="w-24 h-8 max-sm:hidden" />
      </RouterLink>
    </div>

    <div class="flex-center-center gap-4">
      <Dropdown placement="bottom-start">
        <template #label>
          <span class="uppercase font-headings">{{ currentLocale }}</span>
        </template>
        <template #content="{ closePopper }">
          <ul class="dropdown-list min-w-[10rem]">
            <li
              v-for="locale in availableLocales"
              :key="locale"
              class="dropdown-item uppercase w-fit underline-animation font-headings"
              @click="
                changeLocale(locale);
                closePopper();
              "
            >
              {{ $t(`locales.${locale}`) }}
            </li>
          </ul>
        </template>
      </Dropdown>
      <RouterLink
        :to="{ name: `${currentLocale}-bkn-jewelry` }"
        class="uppercase font-headings max-sm:hidden"
        :aria-label="$t('navigation.bknJewelry.title')"
        @click="closeMenu"
      >
        {{ t('navigation.bknJewelry.title') }}
      </RouterLink>
    </div>
  </nav>

  <div class="navigation-menu-container" :class="{ '!translate-y-0': uiStore.navMenuOpen }">
    <div class="flex flex-col h-full font-brand-headings font-light overflow-y-auto py-8">
      <div class="flex flex-col items-center gap-2 m-auto md:gap-4">
        <div class="flex-center-center flex-col gap-6">
          <div class="menu-item">
            <RouterLink
              :to="{ name: 'home' }"
              :aria-label="$t('navigation.home.title')"
              @click="closeMenu"
            >
              {{ t('navigation.home.title') }}
            </RouterLink>
          </div>
          <div class="menu-item">
            <RouterLink
              :to="{ name: 'home', hash: '#history' }"
              class="router-link-inactive"
              :aria-label="$t('navigation.history.title')"
              @click="closeMenu"
            >
              {{ t('navigation.history.title') }}
            </RouterLink>
          </div>
        </div>

        <Icon icon-name="SemiCircleShape" class="menu-item-shape" />

        <div class="flex-center-center flex-col gap-6">
          <div class="menu-item">
            <RouterLink
              :to="{ name: `${currentLocale}-brokn-by-j` }"
              :aria-label="$t('navigation.broknByJ.title')"
              @click="closeMenu"
            >
              {{ t('navigation.broknByJ.title') }}
            </RouterLink>
          </div>
          <div class="menu-item">
            <RouterLink
              :to="{ name: `${currentLocale}-brokn-by-you` }"
              :aria-label="$t('navigation.broknByYou.title')"
              @click="closeMenu"
            >
              {{ t('navigation.broknByYou.title') }}
            </RouterLink>
          </div>
          <div class="menu-item">
            <RouterLink
              :to="{ name: `${currentLocale}-brokn-by` }"
              :aria-label="$t('navigation.broknBy.title')"
              @click="closeMenu"
            >
              {{ t('navigation.broknBy.title') }}
            </RouterLink>
          </div>
        </div>

        <Icon icon-name="VShape" class="menu-item-shape" />

        <div class="flex-center-center flex-col gap-6">
          <div class="menu-item">
            <RouterLink
              :to="{ name: `${currentLocale}-bkn-jewelry` }"
              :aria-label="$t('navigation.bknJewelry.title')"
              @click="closeMenu"
            >
              {{ t('navigation.bknJewelry.title') }}
            </RouterLink>
          </div>
        </div>
      </div>
    </div>

    <Icon
      icon-name="BrandLogo"
      class="absolute text-blue h-48 w-48 rotate-90 scale-[2] opacity-20 bottom-0 right-0 z-[-1] md:h-96 md:w-96 md:-bottom-8"
    />
  </div>
</template>

<style lang="scss" scoped>
.menu-item {
  @apply text-beige-200 text-3xl md:text-4xl hover:text-quartz;

  .router-link-active {
    @apply text-quartz;
  }

  .router-link-inactive {
    @apply text-beige-200 hover:text-quartz;
  }
}
.menu-item-shape {
  @apply w-6 h-6 md:h-10 md:w-10 m-auto;
}

.navigation-menu-container {
  @apply w-screen pt-navbar-h fixed z-40 inset-0 -translate-y-full bg-black text-[2.625rem] transition ease-in-out transform duration-700 opacity-90 overflow-hidden;
}
</style>
