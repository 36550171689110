import { createWebHistory, createRouter } from 'vue-router';
import type { RouteLocationNormalized, NavigationGuardNext, RouteRecordRaw } from 'vue-router';

import { routes } from '@/router';

export const router = createRouter({
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[],
  scrollBehavior(to) {
    if (to.hash) return { el: to.hash };
    return { top: 0 };
  }
});

/* Navigation guard */
// TODO remove if app has no authentification or admin guards
router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const siteAvailable = import.meta.env.VITE_APP_SITE_AVAILABLE;

    if (to.name !== 'soon-available' && siteAvailable === 'false') {
      next({ name: 'soon-available' });
    } else {
      next();
    }
  }
);
