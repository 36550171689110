import { defineStore } from 'pinia';

export interface UiState {
  navMenuOpen: boolean;
  loading: boolean;
  LCPImagesLoaded: {
    homePage: boolean;
    broknByJPage: boolean;
    broknByYouPage: boolean;
    broknByPage: boolean;
  };
}

export const useUiStore = defineStore('ui', {
  state: (): UiState => {
    return {
      navMenuOpen: false,
      loading: false,
      LCPImagesLoaded: {
        homePage: false,
        broknByJPage: false,
        broknByYouPage: false,
        broknByPage: false
      }
    };
  }
});
