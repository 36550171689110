import { ref } from 'vue';

export const useHover = () => {
  const isHovered = ref(false);

  const handleMouseEnter = () => {
    isHovered.value = true;
  };

  const handleMouseLeave = () => {
    isHovered.value = false;
  };

  return {
    isHovered,
    handleMouseEnter,
    handleMouseLeave
  };
};
