<script setup lang="ts">
import { ref, type Ref } from 'vue';
import Popper from 'vue3-popper';

defineProps({
  placement: {
    type: String as () =>
      | 'auto'
      | 'auto-start'
      | 'auto-end'
      | 'top'
      | 'top-start'
      | 'top-end'
      | 'bottom'
      | 'bottom-start'
      | 'bottom-end'
      | 'right'
      | 'right-start'
      | 'right-end'
      | 'left'
      | 'left-start'
      | 'left-end'
      | undefined,
    default: 'auto'
  }
});

const dropdownOpen: Ref<boolean> = ref(false);

const toggleDropdown = () => (dropdownOpen.value = !dropdownOpen.value);
</script>

<template>
  <Popper
    :placement="placement"
    offset-distance="0"
    @open:popper="toggleDropdown"
    @close:popper="toggleDropdown"
  >
    <button class="flex items-center gap-2 h-navbar" type="button">
      <slot name="label" />
      <Icon
        icon-name="ChevronDown"
        class="w-6 h-6 transform rotate-0 transition duration-300 ease-in-out text-quartz"
        :class="{
          'transform !-rotate-180 ': dropdownOpen
        }"
      />
    </button>
    <template #content="{ close }">
      <slot name="content" :close-popper="close" />
    </template>
  </Popper>
</template>

<style>
:root {
  --popper-theme-background-color: theme('colors.white');
  --popper-theme-background-color-hover: theme('colors.white');
  --popper-theme-border-width: 0.06rem;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 0;
  --popper-theme-padding: 0;
  --popper-theme-box-shadow: 0 0.06rem 0.5rem 0 rgb(0 0 0 / 10%);
}
</style>

<style lang="scss">
.dropdown-list {
  @apply flex flex-col gap-4 p-4 text-left;
}
.dropdown-item {
  @apply cursor-pointer text-blue-800;
}
</style>
