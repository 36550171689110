import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { i18n } from '@/assets/translations';

const translatedRoutes: RouteRecordRaw[] = i18n.global.availableLocales.flatMap(
  (locale: string) => [
    {
      path: `/${i18n.global.t('navigation.broknByJ.url', null, { locale })}`,
      name: `${locale}-brokn-by-j`,
      component: () => import('@/components/pages/BroknByJPage.vue'),
      meta: {
        navigationI18nKey: 'broknByJ'
      }
    },
    {
      path: `/${i18n.global.t('navigation.broknByYou.url', null, { locale })}`,
      name: `${locale}-brokn-by-you`,
      component: () => import('@/components/pages/BroknByYouPage.vue'),
      meta: {
        navigationI18nKey: 'broknByYou'
      }
    },
    {
      path: `/${i18n.global.t('navigation.bknJewelry.url', null, { locale })}`,
      name: `${locale}-bkn-jewelry`,
      component: () => import('@/components/pages/BknJewelryPage.vue'),
      meta: {
        navigationI18nKey: 'bknJewelry'
      }
    },
    {
      path: `/${i18n.global.t('navigation.broknBy.url', null, { locale })}`,
      name: `${locale}-brokn-by`,
      component: () => import('@/components/pages/BroknByPage.vue'),
      meta: {
        navigationI18nKey: 'broknBy'
      }
    }
  ]
);

const staticRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/components/pages/HomePage.vue'),
    meta: {
      navigationI18nKey: 'home'
    }
  },
  {
    path: '/bientot-disponible',
    name: 'soon-available',
    component: () => import('@/components/pages/SoonAvailablePage.vue'),
    meta: {
      hideComponent: true
    }
  },
  {
    path: '/systeme-de-design',
    name: 'design-system',
    component: () => import('@/components/pages/DesignSystemPage.vue'),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) => (import.meta.env.VITE_SENTRY_ENV !== 'Production' ? next() : next('/'))
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: () => import('@/components/pages/NotFoundPage.vue'),
    meta: {
      withoutLoader: true
    }
  }
];
export const routes: RouteRecordRaw[] = [...translatedRoutes, ...staticRoutes];
