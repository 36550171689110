<script setup lang="ts">
import { ref } from 'vue';
import { isMobileOnly } from 'mobile-device-detect';

import { useHover } from '@/hooks';
import { appContact } from '@/constants';

const contactEl = ref();
const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();
</script>

<template>
  <div
    v-if="$route.meta.navigationI18nKey"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <a
      v-if="isMobileOnly"
      ref="contactEl"
      rel="noreferrer"
      :href="`sms:${appContact.phone}`"
      target="_blank"
      class="contact-button"
      :aria-label="$t('contactButton.title')"
    >
      <Icon icon-name="PhoneRing" />
    </a>

    <a
      v-else
      ref="contactEl"
      rel="noreferrer"
      :href="`mailto:${appContact.email}?subject=${$t('shared.contactFromPage', {
        page: $t(`navigation.${$route.meta.navigationI18nKey}.title`)
      })}`"
      target="_blank"
      class="contact-button"
      :class="{ active: isHovered }"
      :aria-label="$t('contactButton.title')"
    >
      <Icon v-if="isHovered" icon-name="MailSend" class="mt-1" />
      <Icon v-else icon-name="Mail" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
.contact-button {
  @apply h-[3.125rem] w-[3.125rem] flex-center-center bg-beige rounded-full;
  svg {
    @apply h-[1.875rem] w-[1.875rem];
  }
  &.active {
    @apply bg-beige text-black border-none;
  }
}
</style>
