<script setup lang="ts">
import { computed } from 'vue';
import { kebabCase } from 'lodash';

const props = defineProps<{
  iconName: string;
}>();

const url = computed(
  () => new URL(`/src/assets/icons/icon-${kebabCase(props.iconName)}.svg`, import.meta.url).href
);
</script>

<template>
  <svg
    class="icon"
    fill="currentColor"
    preserve-aspect-ratio="xMidYMid meet"
    aria-hidden="true"
    focusable="false"
  >
    <use :href="`${url}#icon-${kebabCase(props.iconName)}`" />
  </svg>
</template>
