import { i18n } from '@/assets/translations';
import { type GridImages } from '@/interfaces';

type RingRangesNavigation = Readonly<{
  title: string;
  content: string;
  imageDirectoryPath: string;
  extension: string;
  path: { name: string };
  ariaLabel: string;
  altText: string;
  classes?: string;
  cta: string;
}>;

export const ringRangesNavigation: ReadonlyArray<RingRangesNavigation> = Object.freeze([
  {
    title: 'navigation.broknByJ.title',
    content: 'homePage.ranges.broknByJ.content',
    imageDirectoryPath: 'white-tiara-ring',
    extension: 'png',
    path: { name: `${i18n.global.locale.value}-brokn-by-j` },
    ariaLabel: 'navigation.broknByJ.title',
    altText: 'images.altText.ringSilverDiamonds',
    cta: 'homePage.ranges.broknByJ.cta'
  },
  {
    title: 'navigation.broknByYou.title',
    content: 'homePage.ranges.broknByYou.content',
    imageDirectoryPath: 'three-diamonds-ring-up',
    extension: 'png',
    path: { name: `${i18n.global.locale.value}-brokn-by-you` },
    ariaLabel: 'navigation.broknByYou.title',
    altText: 'images.altText.ringGoldPink',
    classes: 'rotate-y-180',
    cta: 'homePage.ranges.broknByYou.cta'
  },
  {
    title: 'navigation.broknBy.title',
    content: 'homePage.ranges.broknBy.content',
    imageDirectoryPath: 'estelle-ring',
    extension: 'png',
    path: { name: `${i18n.global.locale.value}-brokn-by` },
    ariaLabel: 'navigation.broknBy.title',
    altText: 'images.altText.ringGoldPurple',
    classes: 'rotate-[58deg]',
    cta: 'homePage.ranges.broknBy.cta'
  }
]);

type Partner = Readonly<{
  i18nKey: string;
  contents: { i18nKey: string; link?: string }[];
}>;

export const partnersNavigation: ReadonlyArray<Partner> = Object.freeze([
  {
    i18nKey: 'homePage.partners.jewellers',
    contents: [
      {
        i18nKey: 'homePage.partners.jewellers.content0',
        link: 'https://www.linkedin.com/company/gemmes-joaillerie/'
      },
      {
        i18nKey: 'homePage.partners.jewellers.content1',
        link: 'https://www.lagardejewelrydrawing.fr/'
      },
      { i18nKey: 'homePage.partners.jewellers.content2', link: 'http://www.atelierpaloa.com/' }
    ]
  },
  {
    i18nKey: 'homePage.partners.communication',
    contents: [
      {
        i18nKey: 'homePage.partners.communication.content0',
        link: 'https://www.instagram.com/mercredie'
      },
      {
        i18nKey: 'homePage.partners.communication.content1',
        link: 'https://www.linkedin.com/in/pauline-benis-087a07106'
      }
    ]
  },
  {
    i18nKey: 'homePage.partners.photographers',
    contents: [
      {
        i18nKey: 'homePage.partners.photographers.content0',
        link: 'https://www.laurentxaviermoulin.ch/'
      },
      {
        i18nKey: 'homePage.partners.photographers.content1',
        link: 'https://www.lakriee-media.ch/%C3%A0-propos'
      },
      { i18nKey: 'homePage.partners.photographers.content2', link: 'https://www.adrienplaud.com/' }
    ]
  },
  {
    i18nKey: 'homePage.partners.digital',
    contents: [
      { i18nKey: 'homePage.partners.digital.content0', link: 'https://kinoba.fr/' },
      {
        i18nKey: 'homePage.partners.digital.content1',
        link: 'https://www.instagram.com/studioluciole/'
      }
    ]
  },
  {
    i18nKey: 'homePage.partners.others',
    contents: [
      { i18nKey: 'homePage.partners.others.content0' },
      { i18nKey: 'homePage.partners.others.content1', link: 'https://cafehortus.ch/' }
    ]
  }
]);

export const ourMusesSectionGridImages: GridImages = Object.freeze([
  {
    directoryPath: 'petting-cat',
    extension: 'jpg',
    altText: 'images.altText.peopleWithRing'
  },
  {
    directoryPath: 'family-laugh',
    extension: 'jpg',
    altText: 'images.altText.peopleWithRing'
  },
  {
    directoryPath: 'smilling-mom',
    extension: 'jpg',
    altText: 'images.altText.peopleWithRing'
  },
  {
    directoryPath: 'coffee-talk',
    extension: 'jpg',
    altText: 'images.altText.peopleWithRing'
  },
  {
    directoryPath: 'hidden-face-man',
    extension: 'jpg',
    altText: 'images.altText.peopleWithRing'
  },
  {
    directoryPath: 'boot-donning',
    extension: 'jpg',
    altText: 'images.altText.peopleWithRing'
  },
  {
    directoryPath: 'cat-and-stare',
    extension: 'jpg',
    altText: 'images.altText.peopleWithRing'
  }
]);
