<script setup lang="ts">
import { useUiStore } from '@/stores';

const uiStore = useUiStore();
const toggleMenu = () => (uiStore.navMenuOpen = !uiStore.navMenuOpen);
</script>

<template>
  <button
    class="flex-center-center gap-2 flex-col w-6 h-6 border-none cursor-pointer"
    type="button"
    role="button"
    aria-label="menu"
    @click="toggleMenu"
  >
    <div
      :class="['burger-line', { 'rotate-45 translate-y-[.3125rem]': uiStore.navMenuOpen }]"
    ></div>
    <div
      :class="['burger-line', { '-rotate-45 -translate-y-[.3125rem]': uiStore.navMenuOpen }]"
    ></div>
  </button>
</template>

<style lang="scss" scoped>
.burger-line {
  @apply h-0.5 w-full bg-white transition ease-in-out transform duration-300;
}
</style>
